import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import AddAccountButton from "../../../../components/add-account-button";
import LinkedAccount from "../../../../components/linked_account";
import { convertToFloat } from "../../../../utils/convert-number";
import { formatNumberWithDollar } from "../../../../utils/numberFormater";

const LinkedAccountWrap = (props) => {
  const {
    accountId = "",
    institutionLogo = "",
    name = "",
    ownerName = "",
    balance = "",
  } = props;
  return (
    <LinkedAccount
      accountId={accountId}
      logo={institutionLogo}
      ownerName={ownerName}
      name={name}
      value={balance}
    />
  );
};

const renderLinkAccount = (items = []) =>
  items.map((item) => <LinkedAccountWrap key={`${item.id}`} {...item} />);

const getSumBalance = (items = []) =>
  items.reduce((result, item = {}) => {
    const { balance = "0" } = item;
    const balanceFloat = convertToFloat(balance);
    return result + balanceFloat;
  }, 0.0);

const AccountListCarousel = ({ accountList = [], type = "" }) => {
  const isDataLoaded = accountList.length > 0;
  const totalAssetsBalance = getSumBalance(accountList);
  return (
    <ScrollContainer horizontal className="scroll-container">
      <div className="linked-bank linked-account-section">
        <div className="d-flex">
          {isDataLoaded && type === "bankAccount" && (
            <div className="la-container">
              <div className="linked-account-wrapper">
                <div className="linked-account-item-title">Combined</div>
                <span className="linked-account-item-value">
                  {formatNumberWithDollar(
                    convertToFloat(totalAssetsBalance, 2)
                  )}
                </span>
              </div>
            </div>
          )}
          {renderLinkAccount(accountList)}
          {type !== "propertyLoan" && <AddAccountButton />}
        </div>
      </div>
    </ScrollContainer>
  );
};

AccountListCarousel.propTypes = {};

export default AccountListCarousel;
